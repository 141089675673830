exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-us-faqs-js": () => import("./../../../src/pages/contact-us/faqs.js" /* webpackChunkName: "component---src-pages-contact-us-faqs-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-enquiry-form-js": () => import("./../../../src/pages/enquiry-form.js" /* webpackChunkName: "component---src-pages-enquiry-form-js" */),
  "component---src-pages-free-pension-review-for-british-expatriates-js": () => import("./../../../src/pages/free-pension-review-for-british-expatriates.js" /* webpackChunkName: "component---src-pages-free-pension-review-for-british-expatriates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-our-services-currency-transfers-js": () => import("./../../../src/pages/our-services/currency-transfers.js" /* webpackChunkName: "component---src-pages-our-services-currency-transfers-js" */),
  "component---src-pages-our-services-estate-planning-js": () => import("./../../../src/pages/our-services/estate-planning.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-js" */),
  "component---src-pages-our-services-estate-planning-trusts-and-gifting-js": () => import("./../../../src/pages/our-services/estate-planning/trusts-and-gifting.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-trusts-and-gifting-js" */),
  "component---src-pages-our-services-estate-planning-wills-js": () => import("./../../../src/pages/our-services/estate-planning/wills.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-wills-js" */),
  "component---src-pages-our-services-financial-planning-js": () => import("./../../../src/pages/our-services/financial-planning.js" /* webpackChunkName: "component---src-pages-our-services-financial-planning-js" */),
  "component---src-pages-our-services-financial-tools-js": () => import("./../../../src/pages/our-services/financial-tools.js" /* webpackChunkName: "component---src-pages-our-services-financial-tools-js" */),
  "component---src-pages-our-services-financial-tools-mortgage-calculator-js": () => import("./../../../src/pages/our-services/financial-tools/mortgage-calculator.js" /* webpackChunkName: "component---src-pages-our-services-financial-tools-mortgage-calculator-js" */),
  "component---src-pages-our-services-financial-tools-retirement-calculator-js": () => import("./../../../src/pages/our-services/financial-tools/retirement-calculator.js" /* webpackChunkName: "component---src-pages-our-services-financial-tools-retirement-calculator-js" */),
  "component---src-pages-our-services-financial-tools-savings-calculator-js": () => import("./../../../src/pages/our-services/financial-tools/savings-calculator.js" /* webpackChunkName: "component---src-pages-our-services-financial-tools-savings-calculator-js" */),
  "component---src-pages-our-services-insurance-critical-illness-js": () => import("./../../../src/pages/our-services/insurance/critical-illness.js" /* webpackChunkName: "component---src-pages-our-services-insurance-critical-illness-js" */),
  "component---src-pages-our-services-insurance-income-protection-js": () => import("./../../../src/pages/our-services/insurance/income-protection.js" /* webpackChunkName: "component---src-pages-our-services-insurance-income-protection-js" */),
  "component---src-pages-our-services-insurance-js": () => import("./../../../src/pages/our-services/insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-js" */),
  "component---src-pages-our-services-insurance-life-insurance-js": () => import("./../../../src/pages/our-services/insurance/life-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-life-insurance-js" */),
  "component---src-pages-our-services-insurance-medical-insurance-js": () => import("./../../../src/pages/our-services/insurance/medical-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-medical-insurance-js" */),
  "component---src-pages-our-services-investments-education-planning-js": () => import("./../../../src/pages/our-services/investments/education-planning.js" /* webpackChunkName: "component---src-pages-our-services-investments-education-planning-js" */),
  "component---src-pages-our-services-investments-investment-options-js": () => import("./../../../src/pages/our-services/investments/investment-options.js" /* webpackChunkName: "component---src-pages-our-services-investments-investment-options-js" */),
  "component---src-pages-our-services-investments-js": () => import("./../../../src/pages/our-services/investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-js" */),
  "component---src-pages-our-services-investments-portfolio-asset-management-js": () => import("./../../../src/pages/our-services/investments/portfolio-asset-management.js" /* webpackChunkName: "component---src-pages-our-services-investments-portfolio-asset-management-js" */),
  "component---src-pages-our-services-investments-wealth-management-js": () => import("./../../../src/pages/our-services/investments/wealth-management.js" /* webpackChunkName: "component---src-pages-our-services-investments-wealth-management-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-mortgage-property-international-property-js": () => import("./../../../src/pages/our-services/mortgage-property/international-property.js" /* webpackChunkName: "component---src-pages-our-services-mortgage-property-international-property-js" */),
  "component---src-pages-our-services-mortgage-property-js": () => import("./../../../src/pages/our-services/mortgage-property.js" /* webpackChunkName: "component---src-pages-our-services-mortgage-property-js" */),
  "component---src-pages-our-services-mortgage-property-property-investment-js": () => import("./../../../src/pages/our-services/mortgage-property/property-investment.js" /* webpackChunkName: "component---src-pages-our-services-mortgage-property-property-investment-js" */),
  "component---src-pages-our-services-mortgage-property-uk-mortgages-js": () => import("./../../../src/pages/our-services/mortgage-property/uk-mortgages.js" /* webpackChunkName: "component---src-pages-our-services-mortgage-property-uk-mortgages-js" */),
  "component---src-pages-our-services-residency-js": () => import("./../../../src/pages/our-services/residency.js" /* webpackChunkName: "component---src-pages-our-services-residency-js" */),
  "component---src-pages-our-services-retirement-planning-js": () => import("./../../../src/pages/our-services/retirement-planning.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-js" */),
  "component---src-pages-our-services-retirement-planning-pension-transfers-js": () => import("./../../../src/pages/our-services/retirement-planning/pension-transfers.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-pension-transfers-js" */),
  "component---src-pages-our-services-retirement-planning-qrop-js": () => import("./../../../src/pages/our-services/retirement-planning/qrop.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-qrop-js" */),
  "component---src-pages-our-services-retirement-planning-sipp-js": () => import("./../../../src/pages/our-services/retirement-planning/sipp.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-sipp-js" */),
  "component---src-pages-our-services-retirement-planning-uk-state-pension-js": () => import("./../../../src/pages/our-services/retirement-planning/uk-state-pension.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-uk-state-pension-js" */),
  "component---src-pages-our-services-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-js" */),
  "component---src-pages-our-services-tax-planning-spanish-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning/spanish-tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-spanish-tax-planning-js" */),
  "component---src-pages-our-services-tax-planning-wealth-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning/wealth-tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-wealth-tax-planning-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-who-we-are-about-us-js": () => import("./../../../src/pages/who-we-are/about-us.js" /* webpackChunkName: "component---src-pages-who-we-are-about-us-js" */),
  "component---src-pages-who-we-are-news-press-js": () => import("./../../../src/pages/who-we-are/news-press.js" /* webpackChunkName: "component---src-pages-who-we-are-news-press-js" */),
  "component---src-pages-who-we-are-our-locations-js": () => import("./../../../src/pages/who-we-are/our-locations.js" /* webpackChunkName: "component---src-pages-who-we-are-our-locations-js" */),
  "component---src-pages-who-we-are-our-locations-thailand-js": () => import("./../../../src/pages/who-we-are/our-locations/thailand.js" /* webpackChunkName: "component---src-pages-who-we-are-our-locations-thailand-js" */),
  "component---src-pages-who-we-are-our-team-js": () => import("./../../../src/pages/who-we-are/our-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-team-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-single-news-press-js": () => import("./../../../src/templates/singleNewsPress.js" /* webpackChunkName: "component---src-templates-single-news-press-js" */),
  "component---src-templates-single-webinar-js": () => import("./../../../src/templates/singleWebinar.js" /* webpackChunkName: "component---src-templates-single-webinar-js" */)
}

